import Axios from 'axios'
import { URL } from '../config'
import toggle_update      from './toggle_update'
const update_students = async (state,email1,setState) => {
    if(email1) await toggle_update(email1,state,setState)
    try{

    	const response = await Axios.get(`${URL}/students/find_all_students`)
              response.data.students.forEach( (ele,i) => {
                    ele.displayBrief  = state.students[i].displayBrief 
                    ele.displayForm   = state.students[i].displayForm 
                    ele.displayUpdate = state.students[i].displayUpdate
              })
              return response.data.students
    }catch( error ){
    	console.log(error)
    }
}

export default update_students