import React ,{ useState } from 'react'
import Axios   from 'axios'
import { URL } from '../config'

const Login = (props) => {
   const [ password , setPassword ] = useState('')
   const [message, setMessage] = useState({
      text      : 'Login',
      styles    : 'basic_message'
    })

   const handleSubmit = async (e) => {
     e.preventDefault()
     try{
     	const response = await Axios.post(`${URL}/admin/login`,{password})
        if(!response.data.ok ){
        	  setMessage({text:`${response.data.message}`,styles:'error_message'})
        }else{
            setMessage({text:`${response.data.message}`,styles:'success_message'})
            sessionStorage.setItem('token',JSON.stringify(response.data.token))
            setTimeout( () => {props.history.push('/students')},3000)
        }
           setTimeout( () => {setMessage({text:`Login`  ,styles:'basic_message'})},2000)
     }
     catch( error ){
     	console.log(error)
     }
     
   }

   return <div className='form_container_main'>
              <div className={`login_message ${message.styles}`}>
                  <div><h4>{message.text}</h4></div> 
              </div>
   	          <form className='login_form'
   	                onSubmit ={handleSubmit}>
   	          	 <input  className='login_input'
   	          	         type='password'
                         autoComplete='password'
   	          	         onChange={e=>setPassword(e.target.value)}
   	          	         value={password}/>
   	          	 <button className='login_button'>Enter</button>
   	          </form>
          </div>
}

export default Login