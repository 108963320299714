import React , { useContext , useEffect } from 'react'
import StudentBrief from '../components/StudentBrief'
import StudentDetails from '../components/StudentDetails'
import { StateContext } from "../state_context"
import check_student_status from '../helpers/check_student_status'
import Axios   from 'axios'
import { URL } from '../config'

const Students = (props) => {
	const [state,setState] = useContext(StateContext);

	const token = JSON.parse(sessionStorage.getItem('token'))

	useEffect( () => {
		check()
	},[])


const check = async () => {
	try{
		if( token !== null ){
			const response = await Axios.post(`${URL}/admin/check_token`,{token})
			if( !response.data.ok ){
				props.history.push('/')
			}else{
				fetchStudents()
			}
		}else{
			props.history.push('/')
		}
	}
		catch(e){
			console.log(e)
		}
	}

	const fetchStudents = async () => {
		try{
			const response = await Axios.get(`${URL}/students/find_all_students`)
			response.data.students.forEach( (ele,i) => {
				ele.displayBrief  = true ; 
				ele.displayForm   = false;
				ele.displayUpdate = ele.frozen_weeks === 0 ? false : true
			})
var temp = response.data.students.map((s)=>{
			s.endingDate = new Date(s.starting_date)
			// console.log(s)
			s.endingDate.setDate( (s.endingDate.getDate()+( (Number(s.duration)+Number(s.frozen_weeks))*7)) )
			return s
		})
		temp.sort((a,b)=>a.endingDate-b.endingDate)

		setState({...state,students:temp})
//			setState({...state,students:response.data.students})
		}catch(error){
			console.log(error)
		}
	}


return (
	<div className='students_main'>

		<div className='header'><p className='header_text active'>Active Students</p></div>
		{
			state.students.map( (ele,i) => {
				let status = check_student_status(ele.starting_date,ele.duration,ele.frozen_weeks,ele.subscribed)
				return status === 'active'
				? ele.displayBrief
				? <StudentBrief   status={status} {...ele} index={i} key={i}/>
				: <StudentDetails status={status} {...ele} index={i} key={i}/>
				: null
			})
		}
		<div className='header'><p className='header_text future'>Future Students</p></div>
		{
			state.students.map( (ele,i) => {
				let status = check_student_status(ele.starting_date,ele.duration,ele.frozen_weeks,ele.subscribed)
				return status === 'future'
				? ele.displayBrief
				? <StudentBrief   status={status} {...ele} index={i} key={i}/>
				: <StudentDetails status={status} {...ele} index={i} key={i}/>
				: null
			})
		}
		{/*past students*/}
		<div className='header'><p className='header_text past'></p></div>
		{
			state.students.map( (ele,i) => {
				let status = check_student_status(ele.starting_date,ele.duration,ele.frozen_weeks,ele.subscribed)
				return status === 'past'
				? ele.displayBrief
				? <StudentBrief   status={status} {...ele} index={i} key={i}/>
				: <StudentDetails status={status} {...ele} index={i} key={i}/>
				: null
			})
		}
		</div>
		)
}

export default Students
