import React from 'react';
import { BrowserRouter as Router , Route } from 'react-router-dom'

import Login         from './containers/Login'
import Students      from './containers/Students'
import CreateStudent from './containers/CreateStudent'
import SendEmail     from './containers/SendEmail'

import { StateProvider } from "./state_context";
import './App.css'


function App() {
	return <Router>
	<StateProvider>
	<Route exact path='/login'         component={Login}/>
	<Route path='/students'       component={Students}/>
	<Route path='/create_student' component={CreateStudent}/>
	<Route path='/send_email'     component={SendEmail}/>
	</StateProvider>
	</Router> 
}

export default App;
