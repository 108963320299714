import React , { useState , useContext }from 'react'
import Axios from 'axios'
import { URL } from '../config'
import { StateContext } from "../state_context"
import convert_date_object from '../helpers/convert_date_object'

const NotesForm = (props) => {
   const [ note , setNote ] = useState('')
   const [state, setState]  = useContext(StateContext);
   const handleClick = async () => {
   	try{
   	 const response = await Axios.post(`${URL}/students/add_note`,{
                                                              note,
                                                              email1:props.email1,
                                                              date : convert_date_object()
                                                            })
	   	 if( response.data.ok ){
           const response2 = await Axios.get(`${URL}/students/find_all_students`)
           setState({...state,students:response2.data.students})
	   	 }else{
           alert(response.data.message)
	   	 }
   	}catch( error ){
   		console.log(error)
   	}
   }

   return <div className='details_bottom_left'>
            <div className='x_container'>
                <img className='close'
                     alt='close icon' 
                     src={require('../images/close.png')}
                     onClick={()=>props.display()}/>
            </div>
   	        <textarea 
                onChange={e=>setNote(e.target.value)}
   	            placeholder={'add a note here ...'}></textarea>
   	        <button onClick={()=>handleClick()}
	  	     	    className='note_button'>Add note</button>
          </div>
}

export default NotesForm