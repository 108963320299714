import React , { useState, useEffect } from 'react'
import Axios from 'axios'
import { URL } from '../config'

const SendEmail = (props) => {
//==================================================================
//==================================================================
    const [message, setMessage] = useState({
      text   : 'Create student form',
      styles : 'basic_message'
  })
    const [form, setValues] = useState({
        sendTo  : 'all',
        subject : '',
        content : ''
    })

//=====================================================================================
//=====================================================================================
    const token = JSON.parse(sessionStorage.getItem('token'))
    useEffect(  () => {
       check()
   })
    const check = async () => {
       try{
         if( token !== null ){
            const response = await Axios.post(`${URL}/admin/check_token`,{token})
            if( !response.data.ok ){
                props.history.push('/')
            } 
        }else{
            props.history.push('/')
        }
    }
    catch(error){
       return false
   } 
}
//==================================================================
//==================================================================
const handleChange = e => setValues({...form,[e.target.name]:e.target.value})
const handleSubmit = async (e) => {
  e.preventDefault()
  try{
    const response = await Axios.post(`${URL}/emails/send_email`,{
      sendTo : form.sendTo,
      subject: form.subject,
      content: form.content
  })

}
catch(error){
    console.log(error)
}
}
return <div className='form_container_main'>
<form className ='form_container'
onSubmit  = {handleSubmit}>

<div className={`form_message ${message.styles}`}>
<div><h4>{message.text}</h4></div> 
</div>

<div className='form_row_multi'>
<h4>Send to : {form.sendTo}</h4>
<select name='sendTo'
value={form.sendTo} 
onChange = {handleChange}>
<option type='number' value="all">all students</option>
<option type='number' value="past">past students</option>
<option type='number' value="active">active students</option>
<option type='number' value="future">future students</option>
</select>
</div>

<div className='form_row_single'>
<div className='form_row_single_left'><h4>Subject :</h4></div>
<div className='form_row_single_right'>
<input type='text'
name='subject'
onChange = {handleChange}
value={form.subject}/>
</div>
</div>

<div className='form_row_multi'>
<textarea name='content'
onChange = {handleChange}
placeholder={'your email here ...'}
value={form.content}></textarea>

</div>

<div className='form_row_multi'>
<button className='form_button'>Send Emails</button>
</div>
</form>
</div>
}

export default SendEmail