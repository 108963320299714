const check_student_status = (starting_date,duration,frozen_weeks,subscribed) => {
	let today = new Date()
    let ending_date = new Date(starting_date)
    duration += frozen_weeks
    ending_date.setDate(ending_date.getDate()+(duration*7))
    if( today < ending_date && today > new Date(starting_date) || subscribed)return 'active'
    if( today > ending_date )return 'past'
    if( new Date(starting_date) > today )return 'future'
}

export default check_student_status
