import React, { useState ,useEffect } from 'react'
import Axios from 'axios'
import { URL } from '../config'
import convert_from_input from '../helpers/convert_from_input'

const CreateStudent = (props) => {
//=====================================================================================
//=====================================================================================
const [message, setMessage] = useState({
  text      : 'Create student form',
  styles    : 'basic_message'
})
const [form, setValues] = useState({
  name          : "",
  duration      : 0,
  starting_date : "",
  email1        : "",
  email2        : "",
  sessions_total: 0,
  sessions_used : 0,
  frozen_start  : "",
  frozen_weeks  : 0,
  notes         : []
});
//=====================================================================================
//=====================================================================================
const token = JSON.parse(sessionStorage.getItem('token'))
useEffect(  () => {
 check()
})
const check = async () => {
 try{
   if( token !== null ){
    const response = await Axios.post(`${URL}/admin/check_token`,{token})
    if( !response.data.ok ){
        props.history.push('/')
    } 
}else{
    props.history.push('/')
}
}
catch(error){
 return false
} 
}
//=====================================================================================
//=====================================================================================
const handleSubmit = async (e) => {
 e.preventDefault()
 let final = form
 final.duration === '16' 
 ? final.sessions_total = 16
 : final.sessions_total = Number(final.duration)
 final.starting_date = convert_from_input(final.starting_date)
 final.duration = Number(final.duration)
 final.frozen_weeks = Number(final.frozen_weeks)
 try{
    const response = await Axios.post(`${URL}/students/create_student`, final)
    if( !response.data.ok ){
        setMessage({text:`${response.data.message}`,styles:'error_message'})
    }else{
        setValues({
          name          : "",
          duration      : 0,
          starting_date : "",
          email1        : "",
          email2        : "",
          sessions_total: 0,
          sessions_used : 0,
          frozen_start  : "",
          frozen_weeks  : 0,
          notes         : []
      })
        setMessage({text:`${response.data.message}`,styles:'success_message'})

    }
    setTimeout( () => {setMessage({text:`Create student form`,styles:'basic_message'})},3000)
}
catch( error ){
    console.log(error)
}
}

const handleChange = e => setValues({...form,[e.target.name]:e.target.value})

return <div className='create_student_main'>
<form className='form_container'
onSubmit = {handleSubmit}>
<div className={`form_message ${message.styles}`}>
<div><h4>{message.text}</h4></div> 
</div>

<div className='form_row_single'>
<div className='form_row_single_left'><h4>Full Name :</h4></div>
<div className='form_row_single_right'>
<input type='text'
onChange = {handleChange}
name='name'
value={form.name}/>
</div>
</div>

<div className='form_row_single'>
<div className='form_row_single_left'><h4>Email :</h4></div>
<div className='form_row_single_right'>
<input type='email'
name='email1'
onChange = {handleChange}
value={form.email1}/>
</div>
</div>

<div className='form_row_single'>
<div className='form_row_single_left'><h4>Email :</h4></div>
<div className='form_row_single_right'>
<input type='email'
name='email2'
onChange = {handleChange}
value={form.email2}/>
</div>
</div>

<div className='form_row_multi'>
<h4>Starting date</h4>
<input  name='starting_date'
onChange = {handleChange}
type='date'
value={form.starting_date}/>

<h4>Duration</h4>
<select name='duration'
value={form.duration} 
onChange = {handleChange}>
<option type='number' value="0">0</option>
<option type='number' value="2">2</option>
<option type='number' value="4">4</option>
<option type='number' value="9">9</option>
<option type='number' value="13">13</option>
<option type='number' value="16">16</option>
</select>
</div>

<div className='form_row_single'>
<h3>Freeze options</h3>
</div>

<div className='form_row_multi'>
<h4>Starting date</h4>
<input  onChange = {handleChange}
name='frozen_start'
type='date'
value={form.frozen_start}
/>
<h4>Duration</h4>
<select value={form.frozen_weeks}
name='frozen_weeks'
onChange = {handleChange}>
<option value="0">0</option>
<option value="1">1</option>
<option value="2">2</option>
{/*<option value="3">3</option>
<option value="4">4</option>*/}
</select>
</div>

<div className='form_row_multi'>
<button className='form_button'>Create Student</button>
</div>

<div className='form_row_multi'>
<h6 onClick={()=>props.history.push('/students')}>Go to students list</h6>
</div>

</form>
</div>
}

export default CreateStudent