import React, { useState } from 'react'

const StateContext = React.createContext([{}, () => {}]);

const StateProvider = (props) => {
  const [state, setState] = useState({students:[]});

  return (
    <StateContext.Provider value={[state, setState]}>
      {props.children}
    </StateContext.Provider>
  );
};

export { StateContext, StateProvider };