
const convert_date_object = () => {
	let date = new Date()
    let day   = date.getDate() , 
        month = date.getMonth()+1 , 
        year  = date.getFullYear()

    if( day[0]  === '0'){ day = day.split()[1] }
    if(month[0] === '0'){ day = month.split()[1] }
    return `${month}/${day}/${year}`
}

export default convert_date_object