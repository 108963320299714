import React , { useContext} from 'react'
import { StateContext } from "../state_context"
import Axios from 'axios'
import { URL } from '../config'
import update_students from '../helpers/update_students'

const NotesList = (props) => {
   const [state, setState] = useContext(StateContext);
   const handleRemove = async(_id) => {
   	try{
   	  await Axios.post(`${URL}/students/remove_note`,{email1:props.email1,_id})
      const students = await update_students(state)
	    setState({...state,students})
   	}
   	catch(error){
   		console.log(error)
   	}
   }
   return <div className='details_bottom_left'>
             <div className='details_bottom_header'>
             	<h4>Notes</h4>
              {
                props.status !== 'past' 
                ? <h4 onClick={()=>props.display()}>New note</h4>
                : null
              }
             </div>
				{
					props.notes.map( (ele,i) => {
						return <div className='note'>

                      <div className='note_header'>
                         <img className='close' 
                              alt='close icon'
                              src={require('../images/close.png')}
                              onClick={ ()=> handleRemove(ele._id) }/>
                         <div>{ele.date}</div>
                      </div>

						         <p>{ele.note}</p>
						       </div>
					})
				}
		  </div>
}

export default NotesList