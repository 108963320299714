import React, { useContext } from 'react'
import calculate_ending_date from '../helpers/calculate_ending_date'
import toggle_update      from '../helpers/toggle_update'
import { StateContext } from "../state_context"

const FrozenDetails = ({email1,name,frozen_start,frozen_weeks,update_frozen}) => {
   const [state, setState] = useContext(StateContext)
   const today = new Date() , ending_date = calculate_ending_date(frozen_start,frozen_weeks)
   return today > new Date(ending_date) 
   ? <div className='details_bottom_right'>{name} has already been frozen
          <button className='update_button cl_upd'
                   onClick={()=>toggle_update(email1,state,setState)}>update</button>
     </div>
   : <div className='details_bottom_right'>
           <h4>{name} will be frozen</h4>
           <h4>from   : {frozen_start}</h4>
           <h4>untill : {ending_date}</h4>
           <button className='update_button cl_upd'
                   onClick={()=>toggle_update(email1,state,setState)}>update</button>
     </div>
}

export default FrozenDetails