import React , { useContext } from 'react'
import { StateContext } from "../state_context"
import calculate_ending_date from '../helpers/calculate_ending_date'
import get_number_of_weeks   from '../helpers/get_number_of_weeks'
import Axios from 'axios'
import { URL } from '../config'
import Calendar from './Calendar.js'
import FrozenDetails from './FrozenDetails'
import NotesList from './NotesList'
import NotesForm from './NotesForm'
import update_students from '../helpers/update_students' 

const StudentDetails = (props) => {
	const [state, setState] = useContext(StateContext);
	const today    = new Date() , 
          final    = new Date(calculate_ending_date(props.frozen_start,props.frozen_weeks)),
          starting = new Date(props.frozen_start)
	const display = (option=false) => {
	  	 let { students } = state
	  	 if(option){
	  	 	students[props.index].displayBrief = !students[props.index].displayBrief
	  	 }else{
            students[props.index].displayForm  = !students[props.index].displayForm
	  	 } 
	     return setState({...state,students})
	}

	const handleClick = async() => {
		try{
		   await Axios.post(`${URL}/students/increment_one_on_one`,{email1:props.email1})
	       const students = await update_students(state)
	       setState({...state,students})
		}
		catch( error ){
	      console.log( error )
		}
	}

	const update_frozen = async () => {
		try{
		  await Axios.post(`${URL}/students/cancel_frozen`,{email1:props.email1})
		  const students = await update_students(state)
          setState({...state,students})
		}
		catch( error )
		{
           console.log(error)
		}

	}

	return <div>
	          <div className='details_container'>

		       <div onClick={()=>display(true)} className='details_container_left'>
		       	  <img className='arrow' 
		       	       alt='close menu icon'
		       	       src={require('../images/upload.png')}/>
		       </div>
		       <div className='details_container_right'>
		       <div>
		       	 <div className='details_right_inside'><h2>{props.name} </h2> 
		       	                                               { today > starting && today < final
				   	 		                                         ? <div className='brief_right_inside frozen'>
						   	 	                                           <p>frozen</p>
						   	                                           </div>
						   	                                         : null
		       	                                                }
		       	                                       
		       	 </div>
		       	 <div className='details_right_inside'><p>Email 1 : {props.email1}</p></div>
		       	 <div className='details_right_inside'><p>Email 2 : {props.email2}</p></div>
		       	 <div className='details_right_inside'><p>Course  : online {props.duration} weeks</p></div>
		       	 <div className='details_right_inside'>
		       	      <p>starting date : {props.starting_date} ending date : {calculate_ending_date(props.starting_date,props.frozen_weeks,props.duration)}</p>
		       	 </div>
		       	 </div>
		       	 <div>
		       	    {
		       	      props.status !== 'past' && props.status !== 'future'
		       	      ? <div className='details_right_inside'>
		       	           <p>Progress : {get_number_of_weeks(props.starting_date, props.frozen_start, props.frozen_weeks)} weeks out of {props.duration}</p>
		       	        </div>
		       	      : null
		       	    }
		       	    
		       	 
		       	 		{
				   	 		props.duration >= 16 && props.status !== 'past' && props.status !== 'future'
				   	 		? <div className='details_right_inside'>
						   	 	 <p>{props.sessions_used} out of 10</p>
						   	 	 {props.sessions_used < 10 
						   	 	 ? <button onClick={()=>handleClick()} 
						   	 	           className='oneOnOne'>1:1</button>
						   	 	 : null}
						   	  </div>
						   	: null
				   	 	}	
				   	 	</div> 	
		       </div>

	       </div>
	       		   <div className='details_container_bottom'>
                        {
                            props.displayForm
                            ? <NotesForm display={display} {...props}/>
                            : <NotesList status={props.status} display={display} {...props}/>
                        }
				   	  	    
				   	  	{   props.status !== 'past'
				   	  	    ? (
                              	props.displayUpdate
						   	    ? <FrozenDetails update_frozen={update_frozen} {...props}/> 
						   	    : <Calendar {...props}/>
				   	  	    )
				   	  	    : null

				   	  	}
				       </div> 

	       </div>
}

export default StudentDetails