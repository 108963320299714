import React , { useState , useContext , useEffect } from 'react'
import Axios from 'axios'
import { URL } from '../config'
import convert_from_input from '../helpers/convert_from_input'
import update_students    from '../helpers/update_students'
import toggle_update      from '../helpers/toggle_update'
import { StateContext }   from "../state_context"

import '../App.css'

const Calendar = ({email1,frozen_weeks}) => {
   console.log(toggle_update)
  const [ date , setDate] = useState('')
  const [ weeks,setWeeks] = useState(0)
  const [state, setState] = useContext(StateContext)

  const freeze = async () => {
    try{
      const response = await Axios.post(`${URL}/students/freeze_student`,{
                                       date  : convert_from_input(date),
                                       weeks : weeks,
                                       email1: email1
                                     })
      if( response.data.ok ){

         const students  = await update_students(state,email1,setState)
         await setState({...state,students})
         //await toggle_update(email1,state,setState)// here the issue
      }else{
         alert(response.data.message)
      }

    }
    catch( error ){
    	console.log(error)
    }
    
  }

  const inc_dec = (act) => {
  	return act === '+'
		  	? weeks < 4 ? setWeeks(weeks+1) : null
		  	: weeks > 0 ? setWeeks(weeks-1) : null
  }

  return <div className='details_bottom_right'>
  	         <h3>Freeze options</h3>
  	         <div className='bottom_row'>Starting date &nbsp;  
  	            <input type='date' onChange={e=>setDate(e.target.value)}/>
  	         </div>
  	         <div className='bottom_row'>
  	         	Period : {weeks} Weeks 
  	         	<div className='button_container'>
	  	         	<div onClick={()=> inc_dec('-')}
	  	         	     className='square_button'>-</div>
	  	         	<div onClick={()=> inc_dec('+')}
	  	         	     className='square_button'>+</div>
	  	        </div>
  	         </div>
  	         <div className='bottom_row'>
	  	     	<button onClick={()=> freeze()}
	  	     	        className='freeze_button cl_frz'>Freeze</button>
            {
               frozen_weeks !== 0
               ? <button onClick={()=> toggle_update(email1,state,setState)} className='freeze_button cl_can'>Cancel</button>
               : null
            }

	  	     </div>
         </div>
}

export default Calendar