import React , { useContext } from 'react'
import calculate_ending_date  from '../helpers/calculate_ending_date'
import get_number_of_weeks    from '../helpers/get_number_of_weeks'
import update_students        from '../helpers/update_students'  

import Axios   from 'axios'
import { URL } from '../config'
import { StateContext } from "../state_context";


const StudentBrief = ({name,duration,starting_date,sessions_used,frozen_weeks,email1,index,status,frozen_start,subscribed}) => {
  const [state, setState] = useContext(StateContext);
  const today    = new Date() , 
        final    = new Date(calculate_ending_date(frozen_start,frozen_weeks)),
        starting = new Date(frozen_start)
  const handleClick = async() => {
  	try{
       await Axios.post(`${URL}/students/increment_one_on_one`,{email1})
       const students = await update_students(state)
       setState({...state,students})
  	}
  	catch( error ){
       console.log( error )
  	}
  }
    const subs = async() => {
  	try{
       await Axios.post(`${URL}/students/subs`,{email1})
       const students = await update_students(state)
       setState({...state,students})
  	}
  	catch( error ){
       console.log( error )
  	}
  }

  const changeDisplay = () => {
  	 let { students } = state
  	 students[index].displayBrief = !students[index].displayBrief
     setState({...state,students})
  }

  return  <div className='brief_container'>
			   	 <div onClick={()=>changeDisplay()} className='brief_container_left'>
			   	 	<img className='arrow'
			   	 	     alt='open menu icon' 
			   	 	     src={require('../images/download.png')}/>
			   	 </div>
			   	 <div className='brief_container_right'>
				   	 	<div className='brief_right_inside'><h2>{name}</h2></div>
				   	 	<div className='brief_right_inside'>
				   	 		<p>starting date : {starting_date}</p>
				   	 	</div>
				   	 	<div className='brief_right_inside'>
				   	 		<p>ending date : {calculate_ending_date(starting_date,frozen_weeks,duration)}</p>
<button onClick={subs}>{subscribed?'Subscribed':'Not subscribed'}</button>
				   	 	</div>
				   	 	    {
				   	 	    status !== 'past' && status !== 'future'
				   	 	    ? <div className='brief_right_inside'>
				   	 	         <p>Week {get_number_of_weeks(starting_date, frozen_start, frozen_weeks)} out of {duration}</p>
				   	 	      </div>
				   	 	    : null
				   	 	    }
				   	 	{
				   	 		duration >= 16 && status !== 'past' && status !== 'future'
				   	 		? <div className='brief_right_inside'>
						   	 	 <p>{sessions_used} out of 10</p>
						   	 	 {sessions_used < 10 
						   	 	 ? <button onClick={()=>handleClick()} className='oneOnOne'>1:1</button>
						   	 	 : null}
						   	  </div>
						   	: null
				   	 	}
				   	 	{
				   	 		today > starting && today < final
				   	 		? <div className='brief_right_inside frozen'>
						   	 	 <p>frozen</p>
						   	  </div>
						   	: null
				   	 	}

			   	 </div>
		   </div>
}

export default StudentBrief
