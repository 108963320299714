const calculate_ending_date = (starting_date,weeks,duration=0) => {
  let ending_date = new Date(starting_date)
  duration+=weeks
  if (duration > 4){
    ending_date.setDate((ending_date.getDate()-3)+(duration*7))
  }else{ 
    ending_date.setDate((ending_date.getDate())+(duration*7))
  }
    return `${ending_date.getMonth()+1}/${ending_date.getDate()}/${ending_date.getFullYear()}`
  }

  export default calculate_ending_date 